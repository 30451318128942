<template>
  <div>
  <v-navigation-drawer
      v-model="toggle"
      :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
      mobile-breakpoint="960"
      src='https://aibiotics.kr/img/aboutaibioticsimg1.png'
      app
      width="250"
      style="z-index: 100"
  >
    <template v-slot:img="props">
      <v-img
          :gradient="`to bottom, ${barColor}`"
          v-bind="props"
      />
    </template>

    <v-toolbar flat dark :color="'rgba(0,0,0, 0.5)'" class="toolbar text-center">
      <router-link :to="{ name: 'dashBoard' }">
        <img src="img/aib_logo_white.png" width="80%" />

      </router-link>
    </v-toolbar>

    <v-list>




      <v-list-item class="tile" @click="changeRoute('dashBoard', 50)">
        <v-list-item-icon>
          <v-icon>mdi-view-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-title :class="[{'active': selectedIndex === 50}, 'item-title' ]" > Dashboard </v-list-item-title>
      </v-list-item>
      <v-divider light></v-divider>

      <v-list-group
          v-for="(item, index) in items"
          :key="item.title"
          v-model="menuGroupActiveIndex[index]"
          :prepend-icon="item.action"
          no-action
          color="#00FF00"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="item-title " v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
            class="tile"

            v-for="child in item.items"
            :key="child.title"
            @click="changeRoute(child.name, child.index, index)"
        >
          <v-list-item-title :class="[{'active': selectedIndex === child.index}, 'item-sub-title' ]" v-text="child.title"></v-list-item-title>


          <!--          <v-list-item-content>
                      <v-list-item-title v-text="child.title">213</v-list-item-title>
                    </v-list-item-content>
                    -->
        </v-list-item>
      </v-list-group>

      <v-divider light></v-divider>

      <v-list-item class="tile" @click="changeRoute('main_score', 51)">
        <v-list-item-icon>
          <v-icon>mdi-counter</v-icon>
        </v-list-item-icon>
        <v-list-item-title :class="[{'active': selectedIndex === 51}, 'item-title' ]" >Score Table</v-list-item-title>
      </v-list-item>
      <v-list-item class="tile" @click="changeRoute('hashTagEditTable', 52)">
        <v-list-item-icon>
          <v-icon>mdi-tag-multiple-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title :class="[{'active': selectedIndex === 52}, 'item-title' ]" >Hash Tag Edit Table</v-list-item-title>
      </v-list-item>

    </v-list>
  </v-navigation-drawer>
  </div>
</template>

<script>
import DialogEditor from '@/components/dash/DialogEditor'
import {
  mapState,
} from 'vuex'

export default {
  comments : {
    DialogEditor,
  },
  props: {
    toggle: {
      type: Boolean,
      required: false,
      default: true,

    },
  },

  data() {
    return {
      isActive : 0,
      menuIndex : 0,
      groupIndex: [false, false, false, false],
      items: [
        {
          action: 'mdi-account-details',
          items: [
            { title: '고객 문진 내용', name : 'survey', index : 0 },
            { title: '식단 & 식이 취향', name : 'foodTable', index : 1 },
            { title: 'AiB Star Chart', name: 'star', index: 2 },
          ],
          title: '고객 정보',
        },
        {
          action: 'mdi-home-analytics',
          items: [
            { title: 'Aib Chart', name : 'aib', index : 3 },
            { title: 'Index Chart', name : 'balance', index : 4 },
            { title: '종 분석', name : 'species', index : 5 },
            { title: '비율 분석', name : 'piePhylum', index : 6 },
            { title: '유산균 분포', name : 'lactoDash', index : 7 }
          ],
          title: 'AiB 분석',
        },
        {
          action: 'mdi-chart-scatter-plot',
          items: [
            { title: '나이별 다양성', name : 'byage', index : 8 },
            { title: 'Phylum', name : 'byagePhylum', index : 9 },
            { title: 'Genus', name : 'byageGenus', index : 10 },
            { title: 'Species', name : 'byageSpecies', index : 11 },
            { title: '유익균', name : 'beneficialBacteria', index : 15 },
            { title: '유해균', name : 'harmfulBacteria', index : 16 },
            { title: '기회감염균', name : 'opportunisticInfectiousBacteria', index : 17 },
          ],
          title: '연령 분포',
        },
        {
          action: 'mdi-google-analytics',
          items: [
            { title: 'Personal 분석', name : 'personalAnalysis', index : 12 },
            { title: '샘플 비교 분석', name : 'trandSampleCompare', index : 13 },
            { title: '해시태그 군집 분석', name : 'hashTagAnalysis', index : 14 },
          ],
          title: '통계 분석',
        },

     /*   {
          action: 'mdi-content-cut',
          items: [{ title: 'List Item' }],
          title: 'Office',
        },
        {
          action: 'mdi-tag',
          items: [{ title: 'List Item' }],
          title: 'Promotions',
        },*/
      ],
    }
  },

  methods: {
    changeRoute(routeName, selectedIndex, groupIndex) {

      this.$store.commit("setSelectedIndex", selectedIndex);

      for( let i in this.menuGroupActiveIndex) {
        this.menuGroupActiveIndex[i] = false;
      }

      this.menuGroupActiveIndex[groupIndex] = true;
      this.$store.commit("setMenuGroupActiveIndex", this.menuGroupActiveIndex);

      this.$router.push({ name: routeName });
    }
  },

  computed : {
    ...mapState(['barColor', 'barImage']),
    selectedIndex: function (){
      return this.$store.state.selectedIndex;
    },
    menuGroupActiveIndex: function () {
      return this.$store.state.menuGroupActiveIndex;
    }
  }

}
</script>

<style scoped>
.toolbar {
  font-weight: bold;
  font-size: 15px;
}

.toolbar .text {
  padding-left: 15px;
  color: white;
  text-decoration:none;
}

.item-title {
  font-size: 15px;
  font-weight: 500;
  text-align: left;
  margin-left: 0px;
}
.item-sub-title {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  margin-left: 0px;
}
.text-sub-title {
  font-size: 18px;
  margin-left: 10px;
}

.active {
  font-weight: bold;
  color: yellow;

}
.bg-active {
  background-color: #ff0000 !important;
  color : black !important;
}

.bgChange{
  margin: 5px;
  border-radius: 4px;
  background-color: #9d1212;
}

.tile {
  /*
  margin: 5px;
  border-radius: 4px;
  */
  border-radius: 5px;
  background-color: #0c0c0c;
}
.tile:hover {
  background: green;
}
.tile:active {
  background: #9094a1;
}
.v-list-group--no-action:hover {
  border-radius: 5px;
  background-color: green;
}
.v-list-group--active:hover {
  border-radius: 5px;
  background-color: black !important;
}
</style>
